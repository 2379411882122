.loader-container {
    width: 100%;
    height: 5vh; /* Adjust the height as needed */
  }
  
  .spinner-border {
    width: 2rem; /* Adjust the size of the spinner */
    height: 2rem; /* Adjust the size of the spinner */
  }
  
  /* Additional styles to make the spinner circular */
  .spinner-border::after {
    border-width: 0.25em; /* Thickness of the spinner */
  }
  